import { action, thunk } from 'easy-peasy';
import { Http, commonDownloadFile } from '../../common';
import { ENDPOINTS } from '../../constants';

export const model = {
  /** State */
  dataList: {
    list: [],
    total: 0
  },
  document: {},
  downloadStt: false,
  loadingDownload: false,
  loadingDetail: false,

  /** Action */
  setDataList: action((state, payload) => {
    state.dataList = { ...payload };
  }),
  setFile: action((state, payload) => {
    state.list = payload ? payload : null;
  }),
  setDetail: action((state, payload) => {
    state.detail = payload ? payload : null;
  }),
  setDownloadStt: action((state, payload) => {
    state.downloadStt = payload ? payload : null;
  }),
  setLoadingDownload: action((state, payload) => {
    state.loadingDownload = payload;
  }),
  setLoadingDetail: action((state, payload) => {
    if (state?.loadingDetail === undefined) {
      return;
    }
    state.loadingDetail = payload;
  }),
  setDocument: action((state, payload) => {
    state.document = { ...payload };
  }),

  /** Thunk */
  downloadFile: thunk(async (action, payload = {}) => {
    try {
      const url = ENDPOINTS.REPORT_DOWNLOAD;
      const data = await Http.get(url, {
        params: { file: payload.file },
        responseType: 'blob'
      });
      if (data) {
        commonDownloadFile(data, payload);
        setTimeout(() => {
          action.setLoadingDownload(false)
        }, 200);
      }
    } catch (err) {
      setTimeout(() => {
        action.setLoadingDownload(false)
      }, 200);
    }
  }),

  getListMonthlyTenantMeeting: thunk(async (action, payload) => {
    try {
      const url = `${ENDPOINTS.REPORTS}/material`;
      const { data } = await Http.get(url, {
        params: { ...payload }
      });
      if (data) {
        action.setDataList({
          list: data?.list ?? [],
          total: data?.total ?? 0
        });
        action.setDownloadStt(false);
      }
    } catch (err) {
      action.setDataList({
        list: [],
        total: 0
      });
    }
  }),

  getDocument: thunk(async (action, payload) => {
    try {
      if (!(payload !== null)) {
        throw new Error('Invalid Payload');
      }
      action.setLoadingDetail(true);
      const url = `${ENDPOINTS.REPORTS}/detail/${payload.id}`;
      const res = await Http.get(url);
      if (res) {
        action.setLoadingDetail(false);
        return res;
      }
    } catch (err) {
      action.setLoadingDetail(false);
      return err;
    } finally {
      action.setLoadingDetail(false);
    }
  }),

  getPresignedUrlDocument: thunk(async (action, payload) => {
    try {
      if (!(payload !== null)) {
        throw new Error('Invalid Payload');
      }
      const url = `${ENDPOINTS.REPORTS}/download-url?file=${payload.file}`;
      const res = await Http.get(url);
      if (res) {
        return res;
      }
    } catch (err) {
      return err;
    } finally {
    }
  }),
};
