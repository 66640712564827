/* eslint-disable jsx-a11y/anchor-is-valid */
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Input, Menu, Dropdown, Modal } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { reactLocalStorage } from '../../common';
import {
  BREADCRUMB_ROUTES_CONFIG,
  LS_LANGUAGE,
  LS_SESSION_TOKEN,
  LS_USER_INFO,
  LS_ACTIVED_GROUP_ID,
  SS_PREVIOUS_URL,
  SS_SEARCH_VALUE,
  LS_SELECTED_TAB
} from '../../constants';
import './style.scss';
import logo from '../../assets/images/logo.png';
import { LoginOutlined } from '@ant-design/icons';

const { Search } = Input;
const language = [
  { value: 'en', label: 'Eng' },
  { value: 'vi', label: 'VN' }
];

const { SubMenu } = Menu;

const languageLocal = reactLocalStorage.get(LS_LANGUAGE);
const currentUser = reactLocalStorage.get(LS_USER_INFO) ? JSON.parse(reactLocalStorage.get(LS_USER_INFO)) : null;
let tenantName = (currentUser !== null && currentUser.tenant !== null) ? currentUser.tenant.nameVn : '';

function userIsAdmin() {
  if (currentUser !== null) {
    const roles = currentUser.roles.map(role => role.name);
    return roles.includes("ADMIN") || roles.includes("SUPER_ADMIN");
  }
  return false;
}

if (userIsAdmin() && (currentUser !== null && currentUser.userName !== null)) {
  tenantName = currentUser.userName;
}

export const MainHeader = () => {

  // console.log("process.env.NODE_ENV", process.env.NODE_ENV);

  const search = useLocation().search;
  const history = useHistory();
  const groupId = reactLocalStorage.get(LS_ACTIVED_GROUP_ID);
  // For language
  const [t, i18n] = useTranslation('tlip');
  const [valueSearch, setValueSearch] = useState(reactLocalStorage.get(SS_SEARCH_VALUE) ? reactLocalStorage.get(SS_SEARCH_VALUE) : '');
  /**
   * map menu get item not have children
   */
  const newMenu = BREADCRUMB_ROUTES_CONFIG.filter(item => !item.children);
  const { confirm } = Modal;

  let invoiceUrl = '';
  if (groupId == 1) {
    invoiceUrl = 'http://hoadondientu.tlip1.com/';
  } else if (groupId == 2) {
    invoiceUrl = 'http://hoadondientu.tlip2.com/';
  } else if (groupId == 3) {
    invoiceUrl = 'http://hoadondientu.tlip3.com/';
  } else { }

  /**
   * search
   */
  const onSearch = (value) => {
    reactLocalStorage.set(SS_SEARCH_VALUE, value);
    setValueSearch(value);
    if (value) {
      history.push('/search-data');
    } else {
      const pathname = reactLocalStorage.get(SS_PREVIOUS_URL);
      history.push(pathname);
    }
  };

  /**
   * Reset before logout
   */
  const resetBeforeLogout = () => {
    Modal.confirm({
      content: `${t('message.confirmLogout')}`,
      autoFocusButton: null,
      maskClosable: false,
      keyboard: false,
      okText: t('common.ok'),
      cancelText: t('common.cancel'),
      className: "logout-modal",
      onOk: () => {
        localStorage.removeItem(LS_SESSION_TOKEN);
        localStorage.removeItem(LS_USER_INFO);
        localStorage.removeItem(LS_ACTIVED_GROUP_ID);
        localStorage.removeItem(LS_SELECTED_TAB);
        localStorage.removeItem(SS_SEARCH_VALUE);

        window.location.href = `${process.env.REACT_APP_MANAGER_URL}/login`;
      }
    });
  };

  const changelanguage = value => {
    i18n.changeLanguage(value);
    reactLocalStorage.set(LS_LANGUAGE, value);
  };
  const pathname = window.location.pathname;
  const setActiveGrp1 = () => {
    reactLocalStorage.set(LS_ACTIVED_GROUP_ID, "1");
    reactLocalStorage.set(LS_SELECTED_TAB, "1");
    console.log("pathname", pathname);
    if (pathname.includes('emails/')) {
      window.location.href = '/';
    } else {
      window.location.reload();
    }
  };

  const setActiveGrp2 = () => {
    reactLocalStorage.set(LS_ACTIVED_GROUP_ID, "2");
    reactLocalStorage.set(LS_SELECTED_TAB, "2");
    console.log("pathname", pathname);
    if (pathname.includes('waste-water-quality')) {
      window.location.href = '/';
    } else {
      if (pathname.includes('emails/')) {
        window.location.href = '/';
      } else {
        window.location.reload();
      }
    }

  };

  const setActiveGrp3 = () => {
    reactLocalStorage.set(LS_ACTIVED_GROUP_ID, "3");
    reactLocalStorage.set(LS_SELECTED_TAB, "3");
    console.log("pathname", pathname);
    if (pathname.includes('waste-water-quality')) {
      window.location.href = '/';
    } else {
      if (pathname.includes('emails/')) {
        window.location.href = '/';
      } else {
        window.location.reload();
      }
    }
  };

  const serviceMenu = (
    <Menu>
      <Menu.Item key="power-consumsion">
        <Link to="/power-consumsion">
          {t('header.powerConsumptionMonitoring')}
        </Link>
      </Menu.Item>
      {/* <Menu.Item>
        <a rel="noopener noreferrer" href="#">
          {t('header.waterConsumptionMonitoring')}
        </a>
      </Menu.Item> */}
      <Menu.Item key="invoice">
        <a rel="noopener noreferrer" href={`${invoiceUrl}`} target={'_blank'}>
          {t('header.invoice')}
        </a>
      </Menu.Item>
      <Menu.Item key="payment-request">
        <Link to="/payment-request">
          {t('header.paymentRequest')}
        </Link>
      </Menu.Item>
      <Menu.Item key="recruitment-service">
        <Link to="/recruitment-service">
          {t('header.recruimentService')}
        </Link>
      </Menu.Item>
      {(groupId == 1) &&
        <SubMenu key="welfare" title={t('header.welfare')}>
          <Menu.Item key="subMenu1"><a href='https://tlbasebooking.utop.vn/' target={'_blank'}>{t("welfare.subMenu1")}</a></Menu.Item>
          <Menu.Item key="subMenu2">
            <Link to="/welfare/msig">
              {t("welfare.subMenu3")}
            </Link>
          </Menu.Item>
          <Menu.Item key="subMenu4">
            <Link to="/welfare/utop">
              {t("welfare.subMenu4")}
            </Link>
          </Menu.Item>
        </SubMenu>
      }
    </Menu>
  );

  const userMenu = (
    <Menu>
      <Menu.Item key="userInfo" className={history.location.pathname === '/user-detail' ? 'active-dropdown-user' : ''}>
        <a rel="noopener noreferrer" href="/user-detail">
          {t('user.userInfo')}
        </a>
      </Menu.Item>
      <Menu.Item key="changePassword" className={history.location.pathname === '/change-password' ? 'active-dropdown-user' : ''}>
        <a rel="noopener noreferrer" href="/change-password">
          {t('login.changePassword')}
        </a>
      </Menu.Item>
      <hr />
      <Menu.Item key="logout">
        <Link
          to={"#"}
          onClick={() => resetBeforeLogout()}
        >
          {t('header.logout')}
        </Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className="c-main-header">
        <div className="header-wrap">
          <a href="/">
            <img src={logo} alt="tlip Logo" height="80px" />
          </a>
          <div className="change-language-logout">
            {/* <Select
              defaultValue={languageLocal}
              options={language.map(item => {
                return {
                  label: item.label,
                  value: item.value
                };
              })}
              onChange={changelanguage}
            ></Select> */}
            <Avatar icon={<UserOutlined />} />
            <div className="hello-text">
              <div className="tenant-name">
                {t('header.hello')},
                <span className="space"></span>
                <Dropdown overlay={userMenu}>
                  <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    <b>{tenantName ? tenantName : currentUser.name}</b> <DownOutlined />
                  </a>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
        <div className="menu-and-search">
          <div className="menu">
            <>
              <a onClick={setActiveGrp1} className={groupId == "1" ? "active" : ""}>TLIP1</a>
              <a onClick={setActiveGrp2} className={groupId == "2" ? "active" : ""}>TLIP2</a>
              <a onClick={setActiveGrp3} className={groupId == "3" ? "active" : ""}>TLIP3</a>
            </>
            {/*{window.location.hostname == "tl-base.tlipgroup.com" && (*/}
            {/*  <>*/}
            {/*    <a onClick={setActiveGrp1} className={groupId == "1" ? "active" : ""}>TLIP1</a>*/}
            {/*    <a className={groupId == "2" ? "active tmp-disabled" : "tmp-disabled"} disabled>TLIP2</a>*/}
            {/*    <a className={groupId == "3" ? "active tmp-disabled" : "tmp-disabled"} disabled>TLIP3</a>*/}
            {/*  </>*/}
            {/*)}*/}
            {/*{window.location.hostname != "tl-base.tlipgroup.com" && (*/}
            {/*  <>*/}
            {/*    <a onClick={setActiveGrp1} className={groupId == "1" ? "active" : ""}>TLIP1</a>*/}
            {/*    <a onClick={setActiveGrp2} className={groupId == "2" ? "active" : ""}>TLIP2</a>*/}
            {/*    <a onClick={setActiveGrp3} className={groupId == "3" ? "active" : ""}>TLIP3</a>*/}
            {/*  </>*/}
            {/*)}*/}
            <Link to="/related-law">
              {t('header.relatedLaw')}
            </Link>
            <Dropdown overlay={serviceMenu}>
              <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                {t('header.serviceMenu')} <DownOutlined />
              </a>
            </Dropdown>
          </div>
          <div className="search">
            <Search
              placeholder={t('header.inputSearchText')}
              onSearch={onSearch}
              style={{ width: 500 }}
              defaultValue={valueSearch}
              allowClear
            />
          </div>
        </div>
      </div>
    </>
  );
};
