import { action, thunk } from 'easy-peasy';
import { Http, commonDownloadFile } from '../../common';
import { ENDPOINTS } from '../../constants';

export const model = {
  /** State */
  dataListSearch: {
    data: [],
    total: 0
  },
  downloadStt: false,
  loadingDownload: false,

  /** Action */
  setDataListSearch: action((state, payload) => {
    state.dataListSearch = { ...payload };
  }),
  setFile: action((state, payload) => {
    state.list = payload ? payload : null;
  }),
  setDownloadStt: action((state, payload) => {
    state.downloadStt = payload ? payload : null;
  }),
  setLoadingDownload: action((state, payload) => {
    state.loadingDownload = payload;
  }),

  /** Thunk */
  downloadFile: thunk(async (action, payload = {}) => {
    try {
      const url = ENDPOINTS.REPORT_DOWNLOAD;
      const data = await Http.get(url, {
        params: { file: payload.file },
        responseType: 'blob'
      });
      if (data) {
        commonDownloadFile(data, payload);
        setTimeout(() => {
          action.setLoadingDownload(false)
        }, 200);
      }
    } catch (err) {
      setTimeout(() => {
        action.setLoadingDownload(false)
      }, 200);
    }
  }),
  getListSearch: thunk(async (action, payload) => {
    try {
      const paramPage = payload.paramPage;
      const keyword = payload.currentSearch;
      const groupId = payload.tlipId ? payload.tlipId : 1;
      const url = `/general`;
      const { data } = await Http.get(url, {
        params: {
          groupId,
          keyword,
          ...paramPage
        }
      });
      if (data) {
        action.setDataListSearch({
          data: data?.data ?? [],
          total: data?.totalRecords ?? 0
        });
        action.setDownloadStt(false);
      }
    } catch (err) {
      action.setDataListSearch({
        data: [],
        total: 0
      });
    }
  })
};
