import 'antd/dist/antd.css';
import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { reactLocalStorage } from '../common';
import { LS_SESSION_OTP, LS_SESSION_TOKEN } from '../constants';
import store from '../core/create-store';
import { model as modelUserDetail } from '../pages/user-detail/model';
import { model as modelForgotPassword } from '../pages/forgot-password/model';
import { model as modelChangePassword } from '../pages/change-password/model';
import {model as modelLoginOTP} from '../pages/login-otp/model';
import {model as modelLogin} from '../pages/login/model';
import { model as modelMonthlyTenantMeeting } from '../pages/monthly-tenant-meeting/model';
import { model as modelInternalRegulation } from '../pages/internal-regulation/model';
import { model as modelHome } from '../pages/home/model';
import { model as modelEmails } from '../pages/emails/model';
import { model as modelGeneral } from '../pages/general/model';
import { model as modelEmergency } from '../pages/emergency/model';
import { model as modelRelatedLaw } from '../pages/related-law/model';
import { model as modeWasteWaterQuality } from '../pages/waste-water-quality/model';
import { model as modelSrcActivity } from '../pages/src-activity/src-activity-model';
import { model as modelRecruitmentSupport } from '../pages/recruitment-support/recruitment-support-model';
import { model as modelSearchData } from '../pages/search-data/search-data-model';
import { model as modelEvent } from '../pages/event/model';
import Blank from './blank';
import Default from './default';

// Add models
store.addModel('login', modelLogin);
store.addModel('loginOTP', modelLoginOTP);
store.addModel('forgotPassword', modelForgotPassword);
store.addModel('changePassword', modelChangePassword);
store.addModel('monthlyTenantMeeting', modelMonthlyTenantMeeting);
store.addModel('internalRegulation', modelInternalRegulation);
store.addModel('home', modelHome);
store.addModel('userDetail', modelUserDetail);
store.addModel('emails', modelEmails);
store.addModel('general', modelGeneral);
store.addModel('emergency', modelEmergency);
store.addModel('relatedLaw', modelRelatedLaw);
store.addModel('wasteWaterQuality', modeWasteWaterQuality);
store.addModel('srcActivity', modelSrcActivity);
store.addModel('recruitmentSupport', modelRecruitmentSupport);
store.addModel('searchData', modelSearchData);
store.addModel('modelEvent', modelEvent);

export default ({ children }) => {
  const token =
    reactLocalStorage.get(LS_SESSION_TOKEN) ||
    sessionStorage.getItem(LS_SESSION_TOKEN);
  const isOTP = reactLocalStorage.get(LS_SESSION_OTP);

  const location = useLocation();
  if (location.pathname !== '/otp-verify' && isOTP) {
    return <Redirect to="/otp-verify"/>;
  }

  if (location.pathname !== '/login' && location.pathname !== '/forgot-password' && !token && !isOTP) {
    return <Redirect to="/login"/>;
  }

  if (location.pathname === '/login' && token) {
    return <Redirect to="/emails" />;
  }

  if (
    location.pathname === '/login' ||
    location.pathname === '/otp-verify' ||
    location.pathname === '/forgot-password' ||
    location.pathname === '/404' ||
    location.pathname === '/403'
  ) {
    return <Blank>{children}</Blank>;
  }

  return <Default>{children}</Default>;
};
