import { action, thunk } from 'easy-peasy';
import { Http, commonDownloadEmailAttachment } from '../../common';
import { ENDPOINTS } from '../../constants';

export const model = {
  /** State */
  detail: {},
  loading: false,
  loadingPreview: false,
  loadingDownload: false,

  /** Action */
  getDetail: action((state, payload) => {
    state.detail = { ...payload };
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  setLoadingDownload: action((state, payload) => {
    state.loadingDownload = payload;
  }),
  setLoadingPreview: action((state, payload) => {
    if (state?.loadingPreview === undefined) {
      return;
    }

    state.loadingPreview = payload;
  }),

  getEmailDetail: thunk(async (action, payload = {}) => {
    action.setLoading(true);
    try {
      const url = ENDPOINTS.EMAIL + '/' + payload.params.id;
      const { data } = await Http.get(url, {
        params: { type: payload.type }
      });
      if (data) {
        action.getDetail(data.data);
        action.setLoading(false);
      }
    } catch (err) {
      action.getDetail({});
      action.setLoading(false);
    }
  }),

  getPreviewFile: thunk(async (action, payload) => {
    action.setLoadingPreview(true);
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Invalid Payload');
      }
      const config = {
        responseType: 'blob',
      };
      const url = `${ENDPOINTS.EMAIL}/preview/${payload.emailId}/${payload.fileName}`;
      const res = await Http.get(url, config);

      if (res) {
        action.setLoadingPreview(false);
      }

      return res;
    } catch (err) {
      action.setLoadingPreview(false);
    }
  }),

  getDocument: thunk(async (action, payload) => {
    try {
      if (!(payload !== null)) {
        throw new Error('Invalid Payload');
      }
      action.setLoadingDetail(true);
      const url = `${ENDPOINTS.REPORTS}/detail/${payload.id}`;
      const res = await Http.get(url);
      if (res) {
        action.setLoadingDetail(false);
        return res;
      }
    } catch (err) {
      action.setLoadingDetail(false);
      return err;
    } finally {
      action.setLoadingDetail(false);
    }
  }),

  downloadFile: thunk(async (action, payload = {}) => {
    try {
      const url = `${ENDPOINTS.EMAIL}/attachment/${payload.emailId}/${payload.fileNameEncode}`;
      const data = await Http.get(url, {
        params: {},
        responseType: 'blob'
      });
      if (data) {
        commonDownloadEmailAttachment(data, payload);
        setTimeout(() => {
          action.setLoadingDownload(false)
        }, 200);
      }
    } catch (err) {
      setTimeout(() => {
        action.setLoadingDownload(false)
      }, 200);
    }
  }),

  getPresignedUrlDocument: thunk(async (action, payload) => {
    try {
      if (!(payload !== null)) {
        throw new Error('Invalid Payload');
      }
      console.log("getPresignedUrlDocument", payload);
      const url = `${ENDPOINTS.EMAIL}/url/${payload.disposition}/${payload.emailId}/${payload.nameEncode}`;
      const res = await Http.get(url);
      if (res) {
        return res;
      }
    } catch (err) {
      return err;
    } finally {
    }
  }),
};
