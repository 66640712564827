import { action, thunk } from 'easy-peasy';
import { Http, commonDownloadFile } from '../../common';
import { ENDPOINTS } from '../../constants';

export const model = {
  /** State */
  dataList: {
    list: [],
    total: 0
  },
  downloadStt: false,
  loadingDownload: false,

  /** Action */
  setDataList: action((state, payload) => {
    state.dataList = { ...payload };
  }),
  setFile: action((state, payload) => {
    state.list = payload ? payload : null;
  }),
  setDownloadStt: action((state, payload) => {
    state.downloadStt = payload ? payload : null;
  }),
  setLoadingDownload: action((state, payload) => {
    state.loadingDownload = payload;
  }),

  /** Thunk */
  downloadFile: thunk(async (action, payload = {}) => {
    try {
      const url = ENDPOINTS.REPORT_DOWNLOAD;
      const data = await Http.get(url, {
        params: { file: payload.file },
        responseType: 'blob'
      });
      if (data) {
        commonDownloadFile(data, payload);
        setTimeout(() => {
          action.setLoadingDownload(false)
        }, 200);
      }
    } catch (err) {
      setTimeout(() => {
        action.setLoadingDownload(false)
      }, 200);
    }
  }),
  getListInternalRegulation: thunk(async (action, payload = {}) => {
    try {
      if (payload.usrRole === 'SUPER_ADMIN' || payload.usrGrpId == payload.groupId) {
        const url = `${ENDPOINTS.REPORTS}/internal`;
        const { data } = await Http.get(url, {
          params: {
            page: payload.page,
            size: payload.size,
            groupId: payload.groupId,
          }
        });
        if (data) {
          action.setDataList({
            list: data?.list ?? [],
            total: data?.total ?? 0
          });
          action.setDownloadStt(false);
        }
      }
    } catch (err) {
      action.setDataList({
        list: [],
        total: 0
      });
    }
  })
};
