import { action, thunk } from 'easy-peasy';
import { Http } from '../../common';
import { ENDPOINTS } from '../../constants';

export const model = {
  /** State */
  wasteWaterInfo: {
    wqdata: [],
    fileinfo: ''
  },

  /** Action */
  setWasteWaterInfo: action((state, payload) => {
    state.wasteWaterInfo = { ...payload };
  }),
  setFile: action((state, payload) => {
    state.fileinfo = { ...payload };
  }),

  getWasteWaterInfo: thunk(async (action, payload = {}) => {
    try {
      // const url = ENDPOINTS.WASTE_WATER;
      const url = 'https://wq.tlip1.com:8081/chart/csv.php';
      const { data } = await Http.get(url, {});
      if (data) {
        action.setWasteWaterInfo({
          wqdata: data?.wqdata ?? [],
          fileinfo: data?.fileinfo ?? ''
        });
      }
    } catch (err) {
      action.setWasteWaterInfo({
        data: []
      });
    }
  }),
  getFile: thunk(async (action) => {
    try {
      const payload = {
        groupId: 1,
        page: 1,
        size: 1
      }
      const url = `${ENDPOINTS.REPORTS}/waste-water`;
      const { data } = await Http.get(url, {
        params: { ...payload }
      });
      console.log('dataFile:', data);
      if (data) {
        action.setFile({
          list: data?.list ?? [],
          total: data?.total ?? 0
        });
        action.setDownloadStt(false);
      }
    } catch (err) {
      action.setFile({
        list: [],
        total: 0
      });
    }
  })
};
