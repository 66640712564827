import 'antd/dist/antd.css';
import { StoreProvider } from 'easy-peasy';
import i18next from 'i18next';
import React, { Suspense, useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
// import 'antd/dist/antd.dark.css';
import './assets/scss/style.scss';
import { reactLocalStorage } from './common';
import { RouterLoading } from './components';
import { LS_LANGUAGE } from './constants';
import store from './core/create-store';
import Layout from './layout';
import Pages from './pages';
import en from './translations/en.json';
import ja from './translations/ja.json';
import vi from './translations/vi.json';

// Init i18next - Language
i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: 'en', // language to use
  resources: {
    en: { tlip: en },
    ja: { tlip: ja },
    vi: { tlip: vi }
  }
});

function App() {
  const languageLocal = reactLocalStorage.get(LS_LANGUAGE);

  /**
   * Set language to i18next
   */
  useEffect(() => {
    let lang = 'en';

    if (languageLocal) {
      lang = languageLocal;
    } else {
      reactLocalStorage.set(LS_LANGUAGE, lang);
    }

    i18next.changeLanguage(lang);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App">
      <StoreProvider store={store}>
        <Suspense fallback={<RouterLoading />}>
          <I18nextProvider i18n={i18next}>
            <Layout>
              <Pages />
            </Layout>
          </I18nextProvider>
        </Suspense>
      </StoreProvider>
    </div>
  );
}

export default App;
