import {
  HomeOutlined
} from '@ant-design/icons';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFileAlt,
  faLightbulb,
  faTint,
  faIndustry,
  faFileSignature
} from '@fortawesome/free-solid-svg-icons';
import logo_menu from '../assets/images/tlip_logo_menu.png';
import logo_menu_tlip2 from '../assets/images/logo_tlip2.png';
import logo_menu_tlip3 from '../assets/images/logo_tlip3.png';

/**
 * Breadcrumb routes config
 * children: for menus on sidebar
 **/
export const BREADCRUMB_ROUTES_CONFIG = [
  {
    breadcrumbName: 'Tlip Menu',
    icon: <HomeOutlined />,
    isHideInBreadcrumb: true,
    children: [
      // Children for show children menus on sidebar
      {
        path: '/information-from-tlips',
        breadcrumbName: 'information-from-tlips'
      },
      {
        path: '/general-information',
        breadcrumbName: 'general-information'
      },
      {
        path: '/monthly-tenant-meeting',
        breadcrumbName: 'monthly-tenant-meeting'
      },
      {
        path: '/srcr-activity',
        breadcrumbName: 'src-activity'
      }
    ]
  },
  {
    path: '/related-law',
    class: 'other-menu related-law',
    breadcrumbName: 'Luật, Nghị định, Thông tư, Thư chính thức...',
    icon: <FontAwesomeIcon icon={faFileAlt} />
  },
  // {
  //   path: '/power-consumsion',
  //   class: 'other-menu power-consumsion',
  //   breadcrumbName: 'THEO DÕI SẢN LƯỢNG ĐIỆN',
  //   icon: <FontAwesomeIcon icon={faLightbulb} />
  // },
  // {
  //   path: '/water-consumsion',
  //   class: 'other-menu water-consumsion',
  //   breadcrumbName: 'THEO DÕI SẢN LƯỢNG NƯỚC',
  //   icon: <FontAwesomeIcon icon={faTint} />
  // },
  // {
  //   path: '/invoice',
  //   class: 'other-menu invoice',
  //   breadcrumbName: 'HÓA ĐƠN',
  //   icon: <FontAwesomeIcon icon={faFileAlt} />
  // },
  // {
  //   path: '/payment-request',
  //   class: 'other-menu payment-request',
  //   breadcrumbName: 'ĐỀ NGHỊ THANH TOÁN',
  //   icon: <FontAwesomeIcon icon={faFileAlt} />
  // },
  // {
  //   path: '/recruitment-service',
  //   class: 'other-menu recruitment-service',
  //   breadcrumbName: 'DỊCH VỤ TUYỂN DỤNG',
  //   icon: <FontAwesomeIcon icon={faFileSignature} />
  // }
];
export const BREADCRUMB_ROUTES_CONFIG_TLIP_MENU = [
  {
    groupId: '1',
    path: '/tlip1',
    class: 'other-menu tlip2',
    breadcrumbName: 'TLIP1 (Hà Nội)',
    icon: <img src={logo_menu} alt="tlip Logo" />//<FontAwesomeIcon icon={faIndustry} />
  },
  {
    groupId: '2',
    path: '/tlip2',
    class: 'other-menu tlip2',
    breadcrumbName: 'TLIP2 (Hưng Yên)',
    icon: <img src={logo_menu_tlip2} alt="tlip Logo" />//<FontAwesomeIcon icon={faIndustry} />
  },
  {
    groupId: '3',
    path: '/tlip3',
    class: 'other-menu tlip3',
    breadcrumbName: 'TLIP3 (Vĩnh Phúc)',
    icon: <img src={logo_menu_tlip3} alt="tlip Logo" />//<FontAwesomeIcon icon={faIndustry} />
  },
];
