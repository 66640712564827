import { action, thunk } from 'easy-peasy';
import { Http } from '../../common';
import { ENDPOINTS } from '../../constants';

export const model = {
  /** State */
  groupInfo: {
    data: {}
  },

  /** Action */
  setGroupInfo: action((state, payload) => {
    state.groupInfo = {...payload};
  }),

  getGroupInfo: thunk(async (action, payload = {}) => {
    try {
      const url = ENDPOINTS.GROUP_INFO + '/' + payload.groupId;
      const { data } = await Http.get(url, {});
      if (data) {
        action.setGroupInfo({
          data: data?.data ?? []
        });
      }
    } catch (err) {
      action.setGroupInfo({
        data: []
      });
    }
  })
};
