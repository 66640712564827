import { action, thunk } from 'easy-peasy';
import { Http } from '../../common';
import { ENDPOINTS } from '../../constants';

export const model = {
  /** State */
  dataListGeneral: {
    list: [],
    total: 0,
    groupId: 1
  },
  dataListInfra: {
    list: [],
    total: 0,
    groupId: 1
  },
  tabSelect: '3',
  loading: false,

  /** Action */
  setDataListGeneral: action((state, payload) => {
    state.dataListGeneral = { ...payload };
  }),
  setDataListInfra: action((state, payload) => {
    state.dataListInfra = { ...payload };
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  // TODO:
  setTabSelect: action((state, payload) => {
    state.tabSelect = payload;
  }),

  getListEmailGeneral: thunk(async (action, payload = {}) => {
    action.setLoading(true);
    try {
      const url = ENDPOINTS.EMAIL;
      const { data } = await Http.get(url, {
        params: { ...payload }
      });
      if (data) {
        action.setDataListGeneral({
          list: data?.data.list[0].emailItems ?? [],
          total: data?.data.total ?? 0,
          groupId: data?.data.list[0].groupId ?? 1
        });

        action.setLoading(false);
      }
    } catch (err) {
      action.setDataListGeneral({
        list: [],
        total: 0,
        groupId: 1
      });
      action.setLoading(false);
    }
  }),

  getListEmailInfra: thunk(async (action, payload = {}) => {
    action.setLoading(true);
    try {
      const url = ENDPOINTS.EMAIL;
      const { data } = await Http.get(url, {
        params: { ...payload }
      });
      if (data) {
        action.setDataListInfra({
          list: data?.data.list[0].emailItems ?? [],
          total: data?.data.total ?? 0,
          groupId: data?.data.list[0].groupId ?? 1
        });

        action.setLoading(false);
      }
    } catch (err) {
      action.setDataListInfra({
        list: [],
        total: 0,
        groupId: 1
      });
      action.setLoading(false);
    }
  })
};
