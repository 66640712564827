import React from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';
import logo from '../../assets/images/logo.png';
import { Link } from 'react-router-dom';

export const MainFooter = ({ className = '', ...rest }) => {
  // For language
  const [t] = useTranslation('tlip');

  return (
    <div className={`c-main-footer ${className}`} {...rest}>
      <div className="logo">
        <img src={logo} alt="logo" />
        <Link to="/term" className="term-link">
          {t('footer.termsOfUse')}
        </Link>
      </div>
      <div className="copy-right">{t('footer.copyright')}</div>
    </div>
  );
};
