import { action, thunk } from 'easy-peasy';
import { Http, commonDownloadFile } from '../../common';
import { ENDPOINTS } from '../../constants';
import fileDownload from 'js-file-download';

export const model = {
  /** State */
  dataList: {
    list: []
  },
  groupInfo: {
    data: {}
  },
  loadingTenants: false,
  loadingGroup: false,
  loadingDownload: false,

  /** Action */
  setDataList: action((state, payload) => {
    state.dataList = { ...payload };
  }),
  setGroupInfo: action((state, payload) => {
    state.groupInfo = { ...payload };
  }),
  setLoadingTenants: action((state, payload) => {
    state.loadingTenants = payload;
  }),
  setLoadingGroup: action((state, payload) => {
    state.loadingGroup = payload;
  }),
  setLoadingDownload: action((state, payload) => {
    state.loadingDownload = payload;
  }),

  getListTenant: thunk(async (action, payload = {}) => {
    try {
      action.setLoadingTenants(true);
      const url = ENDPOINTS.TENANT;
      const { data } = await Http.get(url, {
        params: { ...payload }
      });
      if (data) {
        action.setDataList({
          list: data?.data.tenants ?? []
        });
        action.setLoadingTenants(false);
      }
    } catch (err) {
      action.setDataList({
        list: []
      });
      action.setLoadingTenants(false);
    }
  }),

  getGroupInfo: thunk(async (action, payload = {}) => {
    try {
      action.setLoadingGroup(true);
      const url = ENDPOINTS.GROUP_INFO + '/' + payload.groupId;
      const { data } = await Http.get(url, {});
      if (data) {
        action.setGroupInfo({
          data: data?.data ?? []
        });
        action.setLoadingGroup(false);
      }
    } catch (err) {
      action.setGroupInfo({
        data: []
      });
      action.setLoadingGroup(false);
    }
  }),

  downloadFile: thunk(async (action, payload = {}) => {
    try {
      action.setLoadingDownload(true)
      const url = `${ENDPOINTS.GROUP_INFO}/download/plot-map`;
      const data = await Http.get(url, {
        params: { file: payload.file },
        responseType: 'blob'
      });
      if (data) {
        commonDownloadFile(data, payload);
        setTimeout(() => {
          action.setLoadingDownload(false)
        }, 200);
      }
    } catch (err) {
      setTimeout(() => {
        action.setLoadingDownload(false)
      }, 200);
    }
  }),

  getPresignedUrlDocument: thunk(async (action, payload) => {
    try {
      if (!(payload !== null)) {
        throw new Error('Invalid Payload');
      }
      console.log("getPresignedUrlDocument", payload);
      const url = `${ENDPOINTS.REPORTS}/download-url?file=${payload.file}&isReport=false`;
      const res = await Http.get(url);
      if (res) {
        return res;
      }
    } catch (err) {
      return err;
    } finally {
    }
  }),
};
