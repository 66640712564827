import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Spin, Empty } from 'antd';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { useStoreState } from 'easy-peasy';

import { PAGE_SIZES, DEFAULT_SORT } from '../../constants';

import './style.scss';

const customLanguageForTableViVN = {
  filterTitle: 'Bộ ',
  filterConfirm: 'Đồng ý',
  filterReset: 'Tạo Lại',
  filterEmptyText: 'Bộ lọc rỗng',
  emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />,
  selectAll: 'Chọn tất cả trên trang hiện tại',
  selectInvert: 'Chọn Ngược Lại',
  selectionAll: 'Chọn Tất Cả',
  sortTitle: 'Sắp xếp',
  expand: 'Mở rộng hàng',
  collapse: 'Thu nhỏ hàng',
  triggerDesc: 'Nhấn để sắp xếp giảm dần',
  triggerAsc: 'Nhấn để sắp xếp tăng dần',
  cancelSort: 'Nhấn để hủy sắp xếp'
};

export const BasicTable = ({
  columns = [],
  data = [],
  total = 0,
  currentPage,
  currentLimit,
  rowKey = 'id',
  showTotal = true, // boolean or callback function
  defaultSortBy = [DEFAULT_SORT],
  loading = false,
  isResponsive = true,
  className = '',
  restPagination,
  onSortChange,
  onTableChange,
  ...rest
}) => {
  // For language
  const [t] = useTranslation('tlip');

  // For global language action
  const globalLanguage = useStoreState(state => state.global.globalLanguage);

  // other state
  const [page, setPage] = useState(null);
  const [limit, setLimit] = useState(null);

  /**
   * Watching change of current page
   */
  useEffect(() => {
    if (!currentPage) { return };

    setPage(currentPage);
  }, [currentPage]);

  /**
   * Watching change of current limit
   */
  useEffect(() => {
    if (!currentLimit) { return };

    setLimit(currentLimit);
  }, [currentLimit]);

  /**
   * Handle table change
   */
  const handleTableChange = (pagination, filters, sorter) => {
    const page = pagination.current;
    const limit = pagination.pageSize;
    const params = {
      page,
      limit,
      order: defaultSortBy,
      filters
    };

    setPage(page);
    setLimit(limit);

    if (sorter.order) {
      params.order = [
        [sorter.field, sorter.order === 'ascend' ? 'ASC' : 'DESC']
      ];
    }

    if (typeof onSortChange === 'function') {
      onSortChange(sorter);
    }

    onTableChange(params);
  };

  return (
    <div className={isResponsive ? 'table-responsive' : ''}>
      {Array.isArray(data) && (
        <Table
          dataSource={data}
          columns={columns}
          rowKey={rowKey} // Fix Warning: Each child in a list should have a unique "key" prop and design
          loading={{
            spinning: loading,
            indicator: <Spin indicator={<Loading3QuartersOutlined spin />} />
          }}
          pagination={
            page &&
            limit && {
              total,
              current: page,
              pageSize: limit,
              pageSizeOptions: PAGE_SIZES,
              showSizeChanger: true,
              showTotal: (totalnumber, range) => {
                return t('common.showTotalOfTable', {
                  start: range[0],
                  end: range[1],
                  total: totalnumber
                });
              },
              ...restPagination
            }
          }
          locale={globalLanguage === 'vi' ? customLanguageForTableViVN : null} // Custom language (on table) for Vietnamese
          className={`c-basic-table ${className}`}
          onChange={handleTableChange}
          {...rest}
        />
      )}
    </div>
  );
};
