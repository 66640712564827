import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import jaJP from 'antd/lib/locale/ja_JP';
import viVN from 'antd/lib/locale/vi_VN';
import { useStoreActions, useStoreState } from 'easy-peasy';
// Language
import i18next from 'i18next';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/fr';
import 'moment/locale/ja';
import 'moment/locale/vi';
import React, { lazy, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { reactLocalStorage } from '../common';
import { LS_LANGUAGE } from '../constants';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-226118211-1');

const Home = lazy(() => import('./home'));
const Emails = lazy(() => import('./emails'));
const Term = lazy(() => import('./term'));
const Login = lazy(() => import('./login'));
const OTPLogin = lazy(() => import('./login-otp'));
const UserDetail = lazy(() => import('./user-detail'));
const ForgotPassword = lazy(() => import('./forgot-password'));
const ChangePassword = lazy(() => import('./change-password'));
const General = lazy(() => import('./general'));
const Emergency = lazy(() => import('./emergency'));
const MonthlyTenantMeeting = lazy(() => import('./monthly-tenant-meeting'));
const InternalRegulation = lazy(() => import('./internal-regulation'));
const Error403 = lazy(() => import('./error-403'));
const Error404 = lazy(() => import('./error-404'));
const RelatedLaw = lazy(() => import('./related-law/index-related-law'));
const SrcActivity = lazy(() => import('./src-activity/src-activity-index'));
const RecruitmentSupport = lazy(() => import('./recruitment-support/recruitment-support-index'));
const RecruitmentServiceLink = lazy(() => import('./recruitment-service-link'));
const IndastrialWaterQuality = lazy(() => import('./industrial-water-quality'));
const WasteWaterQuality = lazy(() => import('./waste-water-quality'));
const PowerConsumsion = lazy(() => import('./power-consumsion'));
const PaymentRequest = lazy(() => import('./payment-request'));
const SolarPower = lazy(() => import('./solar-power'));
const Football = lazy(() => import('./welfare/football'));
const Parking = lazy(() => import('./welfare/parking'));
const Msig = lazy(() => import('./welfare/msig'));
const Utop = lazy(() => import('./welfare/utop'));
const Event = lazy(() => import('./event'));
const SearchData = lazy(() => import('./search-data/search-data-index'));

export default () => {
  const languageLocal = reactLocalStorage.get(LS_LANGUAGE);

  // For global language action
  const setGlobalLanguage = useStoreActions(
    action => action.global.setGlobalLanguage
  );
  const globalLanguage = useStoreState(state => state.global.globalLanguage);

  // Other state
  const [locale, setLocale] = useState(null);

  const location = useLocation();
  useEffect(() => {
    // console.log("Location changed", window.location.pathname + window.location.search);
    // document.title = window.location.pathname;
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [location]);

  /**
   * Set global language by "lang" in localStorage
   */
  useEffect(() => {
    setGlobalLanguage(languageLocal);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Watching change of globalLanguage in STORE
   * Set language to localStorage
   * Language for i18next, for ant design locate, for moment
   */
  useEffect(() => {
    if (!globalLanguage) {
      return;
    }

    if (globalLanguage === 'en') {
      setLocale(enUS);
      moment.locale('en');
    } else if (globalLanguage === 'ja') {
      setLocale(jaJP);
      moment.locale('ja');
    } else if (globalLanguage === 'vi') {
      setLocale(viVN);
      moment.locale('vi');
    } else { }

    i18next.changeLanguage(globalLanguage);
    reactLocalStorage.set(LS_LANGUAGE, globalLanguage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalLanguage]);

  return (
    <ConfigProvider locale={locale}>
      <Switch>
        <Route exact path="/" component={Home} />

        <Route exact path="/emails" component={Home} />

        <Route exact path="/emails/:id?" component={Emails} />

        <Route exact path="/term" component={Term} />

        <Route path="/login" component={Login} />

        <Route path="/otp-verify" component={OTPLogin} />


        <Route path="/user-detail" component={UserDetail} />

        <Route path="/forgot-password" component={ForgotPassword} />

        <Route path="/change-password" component={ChangePassword} />

        <Route path="/general" component={General} />

        <Route path="/emergency" component={Emergency} />

        <Route path="/monthly-tenant-meeting" component={MonthlyTenantMeeting} />

        <Route path="/src-activity" component={SrcActivity} />

        <Route path="/related-law" component={RelatedLaw} />

        <Route path="/internal-regulation" component={InternalRegulation} />

        <Route path="/recruitment-service" component={RecruitmentSupport} />

        <Route path="/recruitment-service-link" component={RecruitmentServiceLink} />

        <Route path="/search-data" component={SearchData} />

        <Route path="/403" component={Error403} />

        <Route path="/404" component={Error404} />

        <Route path="/industrial-water-quality" component={IndastrialWaterQuality} />

        <Route path="/power-consumsion" component={PowerConsumsion} />

        <Route path="/payment-request" component={PaymentRequest} />

        <Route path="/waste-water-quality" component={WasteWaterQuality} />

        <Route path="/solar-power" component={SolarPower} />

        <Route path="/welfare/football" component={Football} />

        <Route path="/welfare/parking" component={Parking} />

        <Route path="/welfare/msig" component={Msig} />

        <Route path="/welfare/utop" component={Utop} />

        <Route path="/event" component={Event} />

        <Redirect to="/404" />
      </Switch>
    </ConfigProvider>
  );
};
