import { action, thunk } from 'easy-peasy';
import { Http } from '../../common';
import { ENDPOINTS } from '../../constants';

export const model = {
  /** State */
  recruitmentInfo: {
    data: {}
  },
  loadingDataRecruiment: false,

  /** Action */
  setRecruitmentInfo: action((state, payload) => {
    state.recruitmentInfo = { ...payload };
  }),
  setLoadingDataRecruiment: action((state, payload) => {
    state.loadingDataRecruiment = payload;
  }),

  /** Thunk */
  getRecruitmentInfo: thunk(async (action, payload = {}) => {
    try {
      action.setLoadingDataRecruiment(true);
      const url = ENDPOINTS.GENERAL_RECRUITMENT_INFO;
      const params = { groupId: payload.groupId };
      const { data } = await Http.get(url, { params: params });
      if (data) {
        action.setRecruitmentInfo({
          data: data?.data ?? {}
        });
      }
    } catch (err) {
      console.log(err);
      action.setRecruitmentInfo({
        data: {}
      });
    } finally {
      action.setLoadingDataRecruiment(false);
    }
  }),
};
