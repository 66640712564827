import { action, thunk } from 'easy-peasy';
import { commonDownloadFile, handleError, Http } from '../../common';
import { ENDPOINTS } from '../../constants';

export const model = {

  /** State */
  listEvent: null,
  listEventInDay: {
    list: [],
    total: 0,
  },
  listReports: {
    list: [],
    total: 0
  },
  loadingList: false,
  loadingListInDay: false,
  loadingSave: false,
  loadingListReports: false,

  /** Action */
  setListEvent: action((state, payload) => {
    state.listEvent = { ...payload };
  }),
  setListReports: action((state, payload) => {
    state.listReports = { ...payload };
  }),
  setLoadingList: action((state, payload) => {
    state.loadingList = payload;
  }),
  setListEventInDay: action((state, payload) => {
    state.listEventInDay = { ...payload };
  }),
  setLoadingListInDay: action((state, payload) => {
    state.loadingListInDay = payload;
  }),
  setLoadingSave: action((state, payload) => {
    if (state?.loadingSave === undefined) {
      return;
    }
    state.loadingSave = payload;
  }),
  setLoadingListReports: action((state, payload) => {
    state.loadingListReports = payload;
  }),

  getEvents: thunk(async (action, payload = {}) => {
    try {
      action.setLoadingList(true);
      const response = await Http.get(ENDPOINTS.EVENT, {
        params: { ...payload }
      });
      if (response) {
        action.setListEvent(response.data);
        action.setLoadingList(false);
      }
    } catch (err) {
      action.setListEvent({});
      action.setLoadingList(false);
    } finally {

    }
  }),

  getEventInDay: thunk(async (action, payload = {}) => {
    try {
      action.setLoadingListInDay(true);
      const response = await Http.get(ENDPOINTS.EVENT, {
        params: { ...payload }
      });
      if (response) {
        action.setListEventInDay(response.data);
        action.setLoadingListInDay(false);
      }
    } catch (err) {
      action.setListEventInDay({
        list: [],
        total: 0,
      });
      action.setLoadingListInDay(false);
    } finally {

    }
  }),

  create: thunk(async (action, payload) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Invalid Payload');
      }

      action.setLoadingSave(true);

      const res = await Http.post(ENDPOINTS.EVENT, payload);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingSave(false);
    }
  }),

  getListReports: thunk(async (action, payload) => {
    try {
      action.setLoadingListReports(true);
      const res = await Http.get(ENDPOINTS.REPORTS + '/' + payload.reportType, {
        params: { ...payload }
      });
      action.setListReports(res.data);
      return res;
    } catch (err) {
      action.setListReports({});
      handleError(err);
    } finally {
      action.setLoadingListReports(false);
    }
  }),

  downloadFile: thunk(async (action, payload = {}) => {
    try {
      const url = ENDPOINTS.REPORT_DOWNLOAD;
      const data = await Http.get(url, {
        params: { file: payload.file },
        responseType: 'blob'
      });
      if (data) {
        commonDownloadFile(data, payload);
      }
    } catch (err) {
      handleError(err);
    }
  }),
};
