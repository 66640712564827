import { action, thunk } from 'easy-peasy';
import { Http, commonDownloadFile } from '../../common';
import { ENDPOINTS } from '../../constants';

export const model = {
  /** State */
  dataList: {
    list: [],
    total: 0
  },
  dataDetailSrc: {
    data: {}
  },
  downloadStt: false,
  loadingDownload: false,

  /** Action */
  setDataList: action((state, payload) => {
    state.dataList = { ...payload };
  }),
  setDetailSrc: action((state, payload) => {
    state.dataDetailSrc = { ...payload };
  }),
  setFile: action((state, payload) => {
    state.list = payload ? payload : null;
  }),
  setDownloadStt: action((state, payload) => {
    state.downloadStt = payload ? payload : null;
  }),
  setLoadingDownload: action((state, payload) => {
    state.loadingDownload = payload;
  }),

  /** Thunk */
  downloadFile: thunk(async (action, payload = {}) => {
    try {
      const url = ENDPOINTS.REPORT_DOWNLOAD;
      const data = await Http.get(url, {
        params: { file: payload.file },
        responseType: 'blob'
      });
      if (data) {
        commonDownloadFile(data, payload);
        setTimeout(() => {
          action.setLoadingDownload(false)
        }, 200);
      }
    } catch (err) {
      setTimeout(() => {
        action.setLoadingDownload(false)
      }, 200);
    }
  }),
  getDetailSrc: thunk(async (action, payload) => {
    try {
      const groupId = payload.tlipId ? payload.tlipId : 1;
      const url = `${ENDPOINTS.GENERAL}/src-activity`;
      const { data } = await Http.get(url, {
        params: { groupId }
      });
      if (data) {
        action.setDetailSrc({
          data: data.data
        });
      }
    } catch (err) {
      action.setDetailSrc({
        header: '',
        content: ''
      });
    }
  }),
  getListSrcActivity: thunk(async (action, payload) => {
    try {
      const paramSearch = payload.paramSearch;
      const groupId = payload.tlipId ? payload.tlipId : 1;
      const url = `${ENDPOINTS.REPORTS}/src-activity`;
      const { data } = await Http.get(url, {
        params: { groupId, ...paramSearch }
      });
      if (data) {
        action.setDataList({
          list: data?.list ?? [],
          total: data?.total ?? 0
        });
        action.setDownloadStt(false);
      }
    } catch (err) {
      action.setDataList({
        list: [],
        total: 0
      });
    }
  })
};
