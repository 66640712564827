import { action, thunk } from 'easy-peasy';
import { Http, commonDownloadFile } from '../../common';
import { ENDPOINTS } from '../../constants';

export const model = {
  /** State */
  dataList: {
    list: [],
    total: 0
  },
  dataListFilterDocument: {
    data: [],
  },
  dataListFilterProvinces: {
    data: [],
  },
  dataListFilterCategories: {
    data: [],
  },
  downloadStt: false,
  loadingDownload: false,

  /** Action */
  setDataList: action((state, payload) => {
    state.dataList = { ...payload };
  }),
  setDataListFilterDocument: action((state, payload) => {
    state.dataListFilterDocument = { ...payload };
  }),
  setDataListFilterProvinces: action((state, payload) => {
    state.dataListFilterProvinces = { ...payload };
  }),
  setDataListFilterCategories: action((state, payload) => {
    state.dataListFilterCategories = { ...payload };
  }),
  setFile: action((state, payload) => {
    state.list = payload ? payload : null;
  }),
  setDownloadStt: action((state, payload) => {
    state.downloadStt = payload ? payload : null;
  }),
  setLoadingDownload: action((state, payload) => {
    state.loadingDownload = payload;
  }),

  /** Thunk */
  downloadFile: thunk(async (action, payload = {}) => {
    try {
      const url = ENDPOINTS.REPORT_DOWNLOAD;
      const data = await Http.get(url, {
        params: { file: payload.file },
        responseType: 'blob'
      });
      if (data) {
        commonDownloadFile(data, payload);
        setTimeout(() => {
          action.setLoadingDownload(false)
        }, 200);
      }
    } catch (err) {
      setTimeout(() => {
        action.setLoadingDownload(false)
      }, 200);
    }
  }),
  getListRelatedLaw: thunk(async (action, payload) => {
    try {
      const paramSearch = payload.paramSearch;
      let url = `${ENDPOINTS.REPORTS}/related-document?groupId=${paramSearch.groupId}&page=${paramSearch.page}&size=${paramSearch.size}`;
      if (payload.dataFilter.length > 0) {
        payload.dataFilter.map(item => {
          url += `&attributes=${item}`;
        });
      }
      const { data } = await Http.get(url);
      if (data) {
        action.setDataList({
          list: data?.list ?? [],
          total: data?.total ?? 0
        });
        action.setDownloadStt(false);
      }
    } catch (err) {
      action.setDataList({
        list: [],
        total: 0
      });
    }
  }),
  getListFilterDocument: thunk(async (action, payload) => {
    try {
      const url = `${ENDPOINTS.DATA_MASTER}/related-document`;
      const { data } = await Http.get(url);
      if (data) {
        action.setDataListFilterProvinces({
          data: data?.data.categoryItems[0].subCategory ?? []
        });
        action.setDataListFilterDocument({
          data: data?.data.categoryItems[1].subCategory ?? []
        });
        action.setDataListFilterCategories({
          data: data?.data.categoryItems[2].subCategory ?? []
        });
      }
    } catch (err) {
      action.setDataListFilterDocument({
        data: []
      });
    }
  }),
  getListFilterProvinces: thunk(async (action, payload) => {
    try {
      const url = `${ENDPOINTS.DATA_MASTER}/PROVINCE`;
      const { data } = await Http.get(url);
      if (data) {
        action.setDataListFilterProvinces({
          data: data?.data ?? []
        });
      }
    } catch (err) {
      action.setDataListFilterProvinces({
        data: []
      });
    }
  }),
  getListFilterCategories: thunk(async (action, payload) => {
    try {
      const url = `${ENDPOINTS.DATA_MASTER}/REPORT_CATEGORY`;
      const { data } = await Http.get(url);
      if (data) {
        action.setDataListFilterCategories({
          data: data?.data ?? []
        });
      }
    } catch (err) {
      action.setDataListFilterCategories({
        data: []
      });
    }
  })
};
