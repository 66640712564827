import { Modal, notification } from 'antd';
import Axios from 'axios';
import i18next from 'i18next';
import { getObjectByValue } from '../common';
import { ENDPOINTS, HTTP_STATUS_CODES, LS_ACTIVED_GROUP_ID, LS_SESSION_TOKEN, LS_USER_INFO } from '../constants';

const token = localStorage.getItem(LS_SESSION_TOKEN);

const Http = Axios.create({
  baseURL: ENDPOINTS.API_URL_BASE,
  headers: { Authorization: `${token}` }
});

//Response intorceptor
Http.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const status = error.response?.status;
    console.log(window.location.pathname);
    if (window.location.pathname !== '/login') {
      if (window.location.pathname === '/forgot-password') {
        if (status === 404) {
          notification.error({
            description: i18next.t('tlip:message.usernameOrEmailNotFound')
          });
        }
        if (status === 401) {
          notification.error({
            description: i18next.t('tlip:message.inactiveAccount')
          });
        }
      } else if (window.location.pathname === '/change-password') {
        if (status === 404) {
          notification.error({
            description: i18next.t('tlip:message.wrongOldPassword')
          });
        }
      } else if (window.location.pathname.includes('/emails')) {
        if (status === 404) {
          notification.error({
            description: i18next.t('tlip:email.emailNotFound')
          });
          setTimeout(
            () => this.setState(window.location.href = '/'),
            3000
          );

        }
      } else if (
        window.location.pathname.includes('/monthly-tenant-meeting')
        || window.location.pathname.includes('/internal-regulation')
        || window.location.pathname.includes('/related-law')
        || window.location.pathname.includes('/src-activity')
        || window.location.pathname.includes('/event')
        || window.location.pathname.includes('/waste-water-quality')
      ) {
        if (status === 400) {
          notification.error({
            description: i18next.t('tlip:message.fileNotFound')
          });
        }
      } else {
        if (status === 401) {
          if (!window.location.pathname.includes('recruitment-service')) {
            localStorage.removeItem(LS_SESSION_TOKEN);
            localStorage.removeItem(LS_USER_INFO);
            localStorage.removeItem(LS_ACTIVED_GROUP_ID);
            window.location.href = `${process.env.REACT_APP_MANAGER_URL}/login`;
          }
        } else {
          notification.error({
            message: HTTP_STATUS_CODES.some(item => item.value === status)
              ? getObjectByValue(status, HTTP_STATUS_CODES)?.label
              : i18next.t('tlip:common.error'),
            description: error.response?.data?.message || ''
          });
        }
      }
    }

    return Promise.reject(error.response ? error.response : error);
  }
);

export { Http };
