import { Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { buildPathNameList } from '../../common';
import { reactLocalStorage } from '../../common';
import {
  BREADCRUMB_ROUTES_CONFIG,
  LS_USER_INFO,
  BREADCRUMB_ROUTES_CONFIG_TLIP_MENU,
  SS_PREVIOUS_URL,
  LS_ACTIVED_GROUP_ID,
  INVOICE_LINK_TLIP1,
  INVOICE_LINK_TLIP2,
  INVOICE_LINK_TLIP3,
  EMERGENCY_LINK,
  CSS_SIDE_BAR
} from '../../constants';
import './style.scss';
import { faFileSignature, faHandHoldingHeart, faFileAlt, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const LeftSidebar = () => {
  const location = useLocation();

  const currentUser = reactLocalStorage.get(LS_USER_INFO) ? JSON.parse(reactLocalStorage.get(LS_USER_INFO)) : null;
  const groupId = reactLocalStorage.get(LS_ACTIVED_GROUP_ID);

  // For language
  const [t] = useTranslation('tlip');

  // Other state
  const [openKeys, setOpenKeys] = useState([]);
  // const [currentPath, setCurrentPath] = useState('');

  const reloadRoute = valuePath => () => {
    switch (valuePath) {
      case '/power-consumsion':
        if (groupId == 1) {
          console.log(1111);
          return 'https://powerconsumption.tlip1.com:8989';
        } else if (groupId == 2) {
          return 'https://powerconsumption.tlip2.com:8990/';
        } else if (groupId == 3) {
          return 'https://powerconsumption.tlip3.com/';
        }
        break;
      case '/water-consumsion':
        return '//tr.tlipgroup.com/en/index.html';
        break;
      case '/invoice':
        if (groupId == 1) {
          return INVOICE_LINK_TLIP1;
        } else if (groupId == 2) {
          return INVOICE_LINK_TLIP1;
        } else if (groupId == 3) {
          return INVOICE_LINK_TLIP1;
        } else { }
        break;
      default:
        return valuePath;
    }
  };

  let invoiceUrl = '';
  if (groupId == 1) {
    invoiceUrl = INVOICE_LINK_TLIP1;
  } else if (groupId == 2) {
    invoiceUrl = INVOICE_LINK_TLIP2;
  } else if (groupId == 3) {
    invoiceUrl = INVOICE_LINK_TLIP3;
  } else { }


  useEffect(() => {
    if (window.location.pathname === '/search-data') {
      return;
    } else {
      setPathCurrent();
    }
  }, [window.location.pathname]);

  const setPathCurrent = () => {
    reactLocalStorage.set(SS_PREVIOUS_URL, window.location.pathname);
  };

  return (
    <div className="left-side-bar-wrap">
      <div className="top-menu">
        <div className="title-menu">TLIP{groupId} menu</div>
        <Link to={'/emails'} className={'line-menu ' + (window.location.pathname == '/' || window.location.pathname == '/emails' ? 'active' : '')}>
          {t('sidebar.informationFromTlips')}
        </Link>
        <div
          to=""
          className={'line-menu ' + (window.location.pathname == '/general'
            || window.location.pathname == '/general/'
            || window.location.pathname == EMERGENCY_LINK
            || window.location.pathname == '/event' ? 'active' : '')}
        >
          {t('sidebar.generalInformation')}
        </div>
        {/* <Link to={'/general-info'} className={`${CSS_SIDE_BAR} ` + (window.location.pathname == '/general-info' || window.location.pathname == '/general-info/' ? "active" : '')}>
          - {t('sidebar.flatAndListCustomer')}
        </Link> */}
        {/* LINK FOR TEST EISDIR */}
        <Link to={'/general'} className={`${CSS_SIDE_BAR} ` + (window.location.pathname == '/general' || window.location.pathname == '/general/' ? "active" : '')}>
          - {t('sidebar.flatAndListCustomer')}
        </Link>
        <Link to={EMERGENCY_LINK} className={`${CSS_SIDE_BAR} ` + (window.location.pathname == EMERGENCY_LINK ? "active" : '')}>
          - LIÊN HỆ KHẨN CẤP
        </Link>
        <Link to={'/event'} className={`${CSS_SIDE_BAR} ` + (window.location.pathname == '/event' ? "active" : '')}>
          - {t('sidebar.eventSchedule')}
        </Link>
        <div to="" className={'line-menu ' + (window.location.pathname == '/monthly-tenant-meeting' ? "active" : '')}>
          HỌP KHÁCH HÀNG HÀNG THÁNG
        </div>
        <Link to="/monthly-tenant-meeting" className={`${CSS_SIDE_BAR} ` + (window.location.pathname == '/monthly-tenant-meeting' ? "active" : '')}>
          - TÀI LIỆU
        </Link>
        <div to="" className={'line-menu ' + (window.location.pathname == '/internal-regulation' ? "active" : '')}>
          QUY ĐỊNH NỘI BỘ
        </div>
        <Link to="/internal-regulation" className={`${CSS_SIDE_BAR} ` + (window.location.pathname == '/internal-regulation' ? "active" : '')}>
          - QUY ĐỊNH NỘI BỘ / CÁC MẪU ĐĂNG KÝ
        </Link>
        <div to="" className={'line-menu ' + (window.location.pathname == '/industrial-water-quality' || window.location.pathname == '/waste-water-quality' ? "active" : '')}>
          MÔI TRƯỜNG
        </div>
        <Link to="/industrial-water-quality" className={`${CSS_SIDE_BAR} ` + (window.location.pathname == '/industrial-water-quality' ? "active" : '')}>
          - CHẤT LƯỢNG NƯỚC CÔNG NGHIỆP
        </Link>
        {groupId == 1 &&
          <Link to="/waste-water-quality" className={`${CSS_SIDE_BAR} ` + (window.location.pathname == '/waste-water-quality' ? "active" : '')}>
            - CHẤT LƯỢNG NƯỚC THẢI
          </Link>
        }
        {/* <Link to="/solar-power" className="line-menu line-normal">
          - ĐIỆN MẶT TRỜI
        </Link> */}
        {(groupId != 3) &&
          <Link to="/src-activity" className={'line-menu ' + (window.location.pathname == '/src-activity' ? "active" : '')}>
            HOẠT ĐỘNG SRC
          </Link>
        }
      </div>
      <Menu
        className="left-side-bar"
        selectedKeys={buildPathNameList(location.pathname)}
        openKeys={openKeys}
        mode="inline"
        onOpenChange={setOpenKeys}
      >
        {BREADCRUMB_ROUTES_CONFIG.map(item => {
          if (!item.children) {
            return (
              <Menu.Item key={item.path} icon={item.icon} className={item.class}>
                <Link
                  target={
                    item.path === '/related-law'
                      || item.path === '/power-consumsion'
                      || item.path === '/recruitment-service'
                      || item.path === '/payment-request' ? '' : '_blank'
                  }
                  to={reloadRoute(item.path)}
                  title={t(`${item.breadcrumbName}`)}
                >
                  {t(`${item.breadcrumbName}`)}
                </Link>
              </Menu.Item>
            );
          }
        })}
        <Menu.Item key="/power-consumsion" icon={<FontAwesomeIcon icon={faLightbulb} />} className="other-menu invoice">
          {groupId == 1 ? (
            <a rel="noopener noreferrer" href={"https://powerconsumption.tlip1.com:8989"} target={'_blank'}>
              THEO DÕI SẢN LƯỢNG ĐIỆN
            </a>
          ): groupId == 2 ? (
            <a rel="noopener noreferrer" href={"https://powerconsumption.tlip2.com:8989"} target={'_blank'}>
              THEO DÕI SẢN LƯỢNG ĐIỆN
            </a>
          ): (
            <a rel="noopener noreferrer" href={"https://powerconsumption.tlip3.com"} target={'_blank'}>
              THEO DÕI SẢN LƯỢNG ĐIỆN
            </a>
          )}
        </Menu.Item>
        <Menu.Item key="/invoice" icon={<FontAwesomeIcon icon={faFileAlt} />} className="other-menu invoice">
          <a rel="noopener noreferrer" href={`${invoiceUrl}`} target={'_blank'}>
            {t('header.invoice')}
          </a>
        </Menu.Item>
        <Menu.Item key="/payment-request" icon={<FontAwesomeIcon icon={faFileAlt} />} className="other-menu invoice">
          {groupId == 1 ? (
            <a rel="noopener noreferrer" href={"https://yctt.tlip1.com:9090"} target={'_blank'}>
              {t('paymentRequest.title')}
            </a>
          ): groupId == 2 ? (
            <a rel="noopener noreferrer" href={"https://yctt.tlip2.com:9091/"} target={'_blank'}>
              {t('paymentRequest.title')}
            </a>
          ): (
            <a rel="noopener noreferrer" href={"https://yctt.tlip3.com"} target={'_blank'}>
              {t('paymentRequest.title')}
            </a>
          )}
        </Menu.Item>
      </Menu>
      <Menu
        className="left-side-bar"
        selectedKeys={buildPathNameList(location.pathname)}
        openKeys={openKeys}
        onOpenChange={setOpenKeys}
        mode="vertical"
      >
        <Menu.SubMenu
          onTitleClick={() => {
            window.open('/recruitment-service', '_self');
          }}
          key="recruitment-support"
          icon={<FontAwesomeIcon icon={faFileSignature} />}
          className="other-menu recruitment-service"
          title={t('recruitmentSupport.title')}
        >
          <Menu.Item key="recruitment-service-link">
            <Menu.Item key="/recruitment-service-link" icon={<FontAwesomeIcon icon={faFileAlt} />} className="other-menu invoice">
              {groupId == 1 ? (
                <a rel="noopener noreferrer" href={"https://tuyendung-tl1.tlipgroup.com"} target={'_blank'}>
                  {t('recruitmentSupport.subMenu')}
                </a>
              ): groupId == 2 ? (
                <a rel="noopener noreferrer" href={"https://tuyendung-tl2.tlipgroup.com/"} target={'_blank'}>
                  {t('recruitmentSupport.subMenu')}
                </a>
              ): (
                <a rel="noopener noreferrer" href={"https://tuyendung-tl3.tlipgroup.com/"} target={'_blank'}>
                  {t('recruitmentSupport.subMenu')}
                </a>
              )}
            </Menu.Item>
          </Menu.Item>
        </Menu.SubMenu>
        {(groupId == 1) &&
          <Menu.SubMenu key="welfare" icon={<FontAwesomeIcon icon={faHandHoldingHeart} />} className="other-menu welfare" title={t('welfare.title')}>
            <Menu.Item className='sub-welfare'>
              <a href='https://tlbasebooking.utop.vn/' target={'_blank'}>{t('welfare.subMenu1')}</a>
            </Menu.Item>
            <Menu.Item className='sub-welfare'>
              <Link to="/welfare/msig">
                {t('welfare.subMenu3')}
              </Link>
            </Menu.Item>
            <Menu.Item className='sub-welfare'>
              <Link to="/welfare/utop">
                {t('welfare.subMenu4')}
              </Link>
            </Menu.Item>
          </Menu.SubMenu>
        }
      </Menu>
      {window.location.hostname != "tl-base.tlipgroup.com" && (
        <>
          <Menu
            className="left-side-bar"
            selectedKeys={buildPathNameList(location.pathname)}
            openKeys={openKeys}
            mode="inline"
            onOpenChange={setOpenKeys}
          >
            {BREADCRUMB_ROUTES_CONFIG_TLIP_MENU.map(item => {
              let urlRedirect = '';
              const pathname = window.location.pathname;
              if ((item.groupId == 2 || item.groupId == 3) && pathname.includes('waste-water-quality')) {
                urlRedirect = '/';
              }
              switch (groupId) {
                case '1':
                  if (item.groupId == 2 || item.groupId == 3) {
                    return (
                      <Menu.Item key={item.path} icon={item.icon} className={item.class}>
                        <a href={urlRedirect} onClick={() => {
                          reactLocalStorage.set(LS_ACTIVED_GROUP_ID, item.groupId);
                        }}>{item.breadcrumbName}</a>
                      </Menu.Item>
                    );
                  }
                  break;
                case '2':
                  if (item.groupId == 1 || item.groupId == 3) {
                    return (
                      <Menu.Item key={item.path} icon={item.icon} className={item.class}>
                        <a href={urlRedirect} onClick={() => {
                          reactLocalStorage.set(LS_ACTIVED_GROUP_ID, item.groupId);
                        }}>{item.breadcrumbName}</a>
                      </Menu.Item>
                    );
                  }
                  break

                default:
                  if (item.groupId == 1 || item.groupId == 2) {
                    return (
                      <Menu.Item key={item.path} icon={item.icon} className={item.class}>
                        <a href={urlRedirect} onClick={() => {
                          reactLocalStorage.set(LS_ACTIVED_GROUP_ID, item.groupId);
                        }}>{item.breadcrumbName}</a>
                      </Menu.Item>
                    );
                  }
                  break;
              }
            })}
          </Menu>
        </>
      )}
    </div>
  );
};
